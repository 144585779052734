import { Redirect, Route } from "react-router-dom";
import { IonApp, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, setupIonicReact, IonImg } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Provider } from "react-redux";
import axios from "axios";
import setupAxios from "./redux/setupAxios";
import store from "./redux/store";
import { default as AuthInit } from "./redux/auth/AuthInit";

import Home from "./pages/Home";
import LotteryRecord from "./pages/LotteryRecord";
import LotteryList from "./pages/LotteryList";
import LotteryPlay from "./pages/LotteryPlay";
import Register from "./pages/Register";
import Account from "./pages/Account";
import BetPackage from "./pages/BetPackage";
import UserInfo from "./pages/UserInfo";
import Agency from "./pages/Agency";
import UserBanks from "./pages/UserBanks";
import AccountAddBank from "./pages/AccountAddBank";
import DepositWithdraw from "./pages/DepositWithdraw";
import Notification from "./pages/Notification";
import BetHistory from "./pages/BetHistory";
import AgencyBalance from "./pages/AgencyBalance";
import AgencyRegister from "./pages/AgencyRegister";
import AgencyRegisterLink from "./pages/AgencyRegisterLink";
import AgencyMemberSearch from "./pages/AgencyMemberSearch";
import ReportWinLoseSearch from "./pages/ReportWinLoseSearch";
import ReportDateSearch from "./pages/ReportDateSearch";
import ReportDailySearch from "./pages/ReportDailySearch";
import Lottery from "./pages/Lottery";
import CardGame from "./pages/CardGame";
import CasinoLive from "./pages/CasinoLive";
import CockFight from "./pages/CockFight";
import Game from "./pages/Game";
import SlotGame from "./pages/SlotGame";
import Sport from "./pages/Sport";
import AgencyLink from "./pages/AgencyLink";
import AgencyTransferMoney from "./pages/AgencyTransferMoney";
import Promotion from "./pages/Promotion";
import CollectAllCredits from "./pages/CollectAllCredits";
import GameCode from "./pages/GameCode";
import VipAccount from "./pages/VipAccount";
import History from "./pages/History";
import GameHistory from "./pages/GameHistory";
import KeepBetHistory from "./pages/KeepBetHistory";
import Information from "./pages/Information";
import BankAccount from "./pages/BankAccount";
import MessageSystem from "./pages/MessageSystem";
import QuickView from "./pages/QuickView";
import AgencyReport from "./pages/AgencyReport";
import GameCodeWM from "./pages/GameCodeWM";
import GameCodeCF from "./pages/GameCodeCF";
import BetReport from "./pages/BetReport";
import NohuGames from "./pages/NohuGames";
import Contactus from "./components/Contactus";
import RewardPoints from "./pages/RewardPoints";
import RewardsExchange from "./pages/RewardsExchange";
import HistoryAddPoints from "./pages/HistoryAddPoints";
import RewardHistory from "./pages/RewardHistory";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import "./App.css";
import { IMG_MB } from "./constants";

setupIonicReact();

//axios
setupAxios(axios, store);

// const PageNotFound: React.FC = () => {
// 	const urlParams = new URLSearchParams(window.location.search);
// 	const regId = urlParams.get("reg-id");
// 	if (regId) {
// 		return <Redirect to={`/register?reg-id=${regId}`} />;
// 	}

// 	return <Redirect to="/home" />;
// };

const App: React.FC = () => (
	<Provider store={store}>
		<IonApp>
			<AuthInit>
				<main className="main-page">
					<IonReactRouter>
						<IonTabs>
							<IonRouterOutlet>
								<Route exact path="/home">
									<Home />
								</Route>
								<Route exact path="/lottery-record">
									<LotteryRecord />
								</Route>
								<Route exact path="/account">
									<Account />
								</Route>
								<Route exact path="/agency">
									<Agency />
								</Route>
								<Route exact path="/reward-points">
									<RewardPoints />
								</Route>
								<Route exact path="/rewards-exchange">
									<RewardsExchange />
								</Route>
								<Route exact path="/history-add-points">
									<HistoryAddPoints />
								</Route>
								<Route exact path="/reward-history">
									<RewardHistory />
								</Route>
								<Route exact path="/bet-package">
									<BetPackage />
								</Route>
								<Route exact path="/lottery">
									<Lottery />
								</Route>
								<Route exact path="/card-game">
									<CardGame />
								</Route>
								<Route exact path="/casino-live">
									<CasinoLive />
								</Route>
								<Route exact path="/cock-fight">
									<CockFight />
								</Route>
								<Route exact path="/game">
									<Game />
								</Route>
								<Route exact path="/slot-game">
									<SlotGame />
								</Route>
								<Route exact path="/sport">
									<Sport />
								</Route>
								<Route exact path="/cock-fight/odin" component={GameCodeCF} />
								<Route exact path="/sport/:gameCode" component={GameCode} />
								<Route exact path="/sport-2/:gameCode" component={GameCode} />
								<Route exact path="/casino-live/:gameCode" component={GameCodeWM} />
								<Route exact path="/casino-live-1/:gameCode" component={GameCodeWM} />
								<Route exact path="/casino-live-2/:gameCode" component={GameCodeWM} />
								<Route exact path="/">
									<Redirect to="/home" />
								</Route>
								<Route exact path="/bet-history">
									<BetHistory />
								</Route>
								<Route exact path="/agency-report">
									<AgencyReport />
								</Route>
								<Route exact path="/bet-report">
									<BetReport />
								</Route>
								<Route exact path="/keep-bet-history">
									<KeepBetHistory />
								</Route>
								<Route exact path="/game-history">
									<GameHistory />
								</Route>
								<Route exact path="/history">
									<History />
								</Route>
								<Route exact path="/information">
									<Information />
								</Route>
								<Route exact path="/agency-balance">
									<AgencyBalance />
								</Route>
								<Route exact path="/agency-link">
									<AgencyLink />
								</Route>
								<Route exact path="/agency-register">
									<AgencyRegister />
								</Route>
								<Route exact path="/agency-members">
									<AgencyMemberSearch />
								</Route>
								<Route exact path="/agency-transfer">
									<AgencyTransferMoney />
								</Route>
								<Route exact path="/promotion">
									<Promotion />
								</Route>
								<Route exact path="/collect-all-credits">
									<CollectAllCredits />
								</Route>
								<Route exact path="/user-banks">
									<UserBanks />
								</Route>
								<Route exact path="/vip-account">
									<VipAccount />
								</Route>
								<Route exact path="/account-add-bank">
									<AccountAddBank />
								</Route>
								<Route exact path="/deposit-withdraw/:tab" component={DepositWithdraw} />
								<Route exact path="/notification">
									<Notification />
								</Route>
								<Route exact path="/agency-register-link">
									<AgencyRegisterLink />
								</Route>
								<Route exact path="/message-system">
									<MessageSystem />
								</Route>
								<Route exact path="/quick-view">
									<QuickView />
								</Route>
								<Route exact path="/report-win-lose-search">
									<ReportWinLoseSearch />
								</Route>
								<Route exact path="/report-date-search">
									<ReportDateSearch />
								</Route>
								<Route exact path="/report-daily-search">
									<ReportDailySearch />
								</Route>
								<Route exact path="/register">
									<Register />
								</Route>
								<Route exact path="/user-info">
									<UserInfo />
								</Route>
								<Route exact path="/bank-account">
									<BankAccount />
								</Route>
								<Route exact path="/lottery-list/:codeName" component={LotteryList} />
								{/* <Route component={PageNotFound} /> */}
							</IonRouterOutlet>
							<IonTabBar slot="bottom" className="tab-footer">
								<IonTabButton tab="home" href="/home">
									<IonImg src={`${IMG_MB}assets/icon/tab-home.png`} />
									<IonLabel>Trang Chủ</IonLabel>
								</IonTabButton>
								<IonTabButton tab="deposit" href="/deposit-withdraw/0">
									<IonImg src={`${IMG_MB}assets/icon/tab-naptien.png`} />
									<IonLabel>Nạp Tiền</IonLabel>
								</IonTabButton>
								<IonTabButton tab="withdraw" href="/deposit-withdraw/2">
									<IonImg src={`${IMG_MB}assets/icon/tab-ruttien.png`} />
									<IonLabel>Rút Tiền</IonLabel>
								</IonTabButton>
								<IonTabButton tab="khuyenmai" href="/promotion">
									<IonImg src={`${IMG_MB}assets/icon/tab-khuyenmai.png`} />
									<IonLabel>Khuyến Mãi</IonLabel>
								</IonTabButton>
								<IonTabButton className="tab-account" tab="account" href="/account">
									<IonImg src={`${IMG_MB}assets/icon/tab-taikhoan.png`} />
									<IonLabel>Tài Khoản</IonLabel>
								</IonTabButton>
							</IonTabBar>
						</IonTabs>
						<Route exact path="/lottery-play/:codeName" component={LotteryPlay} />
						<Route exact path="/nohu-games" component={NohuGames} />
					</IonReactRouter>
					<Contactus />
				</main>
			</AuthInit>
		</IonApp>
	</Provider>
);

export default App;
