import { persistReducer } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { takeLatest } from "redux-saga/effects";
// import { put, takeLatest } from "redux-saga/effects";
// import { getUserByToken } from "./authCrud";
// import { get } from "lodash";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  SetLogoutTime: "[Set Logout Time] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  deviceId: undefined,
  logoutTime: undefined,
};

export const reducer = persistReducer({ storage: AsyncStorage, key: "vibet88-auth", whitelist: ["authToken", "user", 'deviceId', 'logoutTime'] }, (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.Login: {
      const { Token: authToken, user, deviceId, logoutTime } = action.payload;
      return { ...state, authToken, user, deviceId, logoutTime };
    }

    case actionTypes.SetLogoutTime: {
      const { logoutTime } = action.payload;
      return { ...state, logoutTime };
    }

    case actionTypes.Logout: {
      // TODO: Change this code. Actions in reducer aren't allowed.
      return initialAuthState;
    }

    case actionTypes.UserLoaded: {
      const { user } = action.payload;
      return { ...state, user };
    }

    case actionTypes.SetUser: {
      const { user } = action.payload;
      return { ...state, user };
    }

    default:
      return state;
  }
});

export const actions = {
  login: (data) => ({ type: actionTypes.Login, payload: data }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  updateLogoutTime: (logoutTime) => ({ type: actionTypes.SetLogoutTime, payload: { logoutTime } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    // yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    // yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    // const resUser = yield getUserByToken();
    // yield put(actions.fulfillUser(get(resUser, "data.data", null)));
  });
}
