import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonIcon, IonButton } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";
import { getAgentIncomeReportCurrent, getAgentIncomeReportLast } from "../redux/auth/authCrud";
import { formatMoney } from "../util";

import "./Agency.css";

const AgencyReport: React.FC = () => {
	const history = useHistory();

	const [dataIncomeAgent, setDataIncomeAgent] = useState<any>({});
	const [dataIncomeAgentLastMonth, setDataIncomeAgentLastMonth] = useState<any>({});

	const GetIncomeAgentReport = async () => {
		const res = await getAgentIncomeReportCurrent();
		if (res && res.data) {
			setDataIncomeAgent(res.data);
		}
	};

	const GetIncomeAgentLastMonth = async () => {
		const res = await getAgentIncomeReportLast();
		if (res && res.data) {
			setDataIncomeAgentLastMonth(res.data);
		}
	};

	useEffect(() => {
		GetIncomeAgentReport();
		GetIncomeAgentLastMonth();
	}, []);

	return (
		<IonPage>
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/agency")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Báo Cáo Thu Nhập
					</IonButton>
				</div>
			</div>
			<IonContent className="app-agency">
				<div className="flex gap-3 p-4 flex-col">
					<div className="w-full flex flex-col">
						<div className="w-full">
							<div className="sic-title-page">
								<span> Báo cáo kỳ này </span>
								<hr />
							</div>
						</div>
						<div className="border w-full p-3 border-solid border-F5BC27 rounded-t-2xl bg-DBDBDB">
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium  text-black">Tổng thành viên hiện tại</span>
								<input
									readOnly
									value={dataIncomeAgent?.TotalMember}
									className={`bg-DBDBDB ${
										dataIncomeAgent?.TotalMember < 0 ? "text-FF0000" : "text-276196"
									} font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Đăng ký mới kỳ này</span>
								<input
									readOnly
									value={dataIncomeAgent?.NewMember}
									className={`bg-DBDBDB ${
										dataIncomeAgent?.NewMember < 0 ? "text-FF0000" : "text-276196"
									} font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Thành viên cược kỳ này</span>
								<input
									readOnly
									value={dataIncomeAgent?.BetMember}
									className={`bg-DBDBDB ${
										dataIncomeAgent?.BetMember < 0 ? "text-FF0000" : "text-276196"
									} font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Tổng thành viên nạp đầu kỳ này</span>
								<input
									readOnly
									value={dataIncomeAgent?.DepositMember}
									className={`bg-DBDBDB ${
										dataIncomeAgent?.DepositMember < 0 ? "text-FF0000" : "text-276196"
									} font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Tổng thành viên cược hợp lệ</span>
								<input
									readOnly
									value={dataIncomeAgent?.DepositMemberValid}
									className={`bg-DBDBDB ${
										dataIncomeAgent?.DepositMemberValid < 0 ? "text-FF0000" : "text-276196"
									} font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
						</div>
						<div className="border w-full p-3 border-solid border-F5BC27 border-t-0 rounded-b-2xl bg-[#EAEAEA]">
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium  text-black">Điểm thưởng</span>
								<input
									readOnly
									value={formatMoney(dataIncomeAgent?.CommissionFee)}
									className={`bg-DBDBDB text-FF0000 font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium  text-black">Chi phí khuyến mãi</span>
								<input
									readOnly
									value={formatMoney(dataIncomeAgent?.PromotionFee)}
									className={`bg-DBDBDB text-FF0000 font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Chi phí GiftCode</span>
								<input
									readOnly
									value={formatMoney(dataIncomeAgent?.GiftCodeFee)}
									className={`bg-DBDBDB text-FF0000 font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Tổng thắng thua</span>
								<input
									readOnly
									value={formatMoney(dataIncomeAgent?.WinLose)}
									className={`bg-DBDBDB ${
										dataIncomeAgent?.WinLose < 0 ? "text-276196" : "text-FF0000"
									} font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
						</div>
					</div>
					<div className="w-full flex flex-col">
						<div className="w-full">
							<div className="sic-title-page">
								<span> Báo cáo kỳ trước </span>
								<hr />
							</div>
						</div>
						<div className="border w-full p-3 border-solid border-F5BC27 rounded-t-2xl bg-DBDBDB">
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Tổng thành viên</span>
								<input
									readOnly
									value={dataIncomeAgentLastMonth?.TotalMember}
									className={`bg-DBDBDB ${
										dataIncomeAgentLastMonth?.TotalMember < 0 ? "text-FF0000" : "text-276196"
									} font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Tổng đăng ký mới</span>
								<input
									readOnly
									value={dataIncomeAgentLastMonth?.NewMember}
									className={`bg-DBDBDB ${
										dataIncomeAgentLastMonth?.NewMember < 0 ? "text-FF0000" : "text-276196"
									} font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Tổng thành viên cược</span>
								<input
									readOnly
									value={dataIncomeAgentLastMonth?.BetMember}
									className={`bg-DBDBDB ${
										dataIncomeAgentLastMonth?.BetMember < 0 ? "text-FF0000" : "text-276196"
									} font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Tổng thành viên cược hợp lệ</span>
								<input
									readOnly
									value={dataIncomeAgentLastMonth?.DepositMemberValid}
									className={`bg-DBDBDB ${
										dataIncomeAgentLastMonth?.DepositMemberValid < 0 ? "text-FF0000" : "text-276196"
									} font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Tổng thắng thua</span>
								<input
									readOnly
									value={formatMoney(dataIncomeAgentLastMonth?.WinLose)}
									className={`bg-DBDBDB ${
										dataIncomeAgentLastMonth?.WinLose < 0 ? "text-276196" : "text-FF0000"
									} font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
						</div>
						<div className="border w-full p-3 border-solid border-F5BC27 border-t-0 rounded-b-2xl bg-[#EAEAEA]">
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium  text-black">Chi phí quản lý</span>
								<input
									readOnly
									value={formatMoney(dataIncomeAgentLastMonth?.ManageFee)}
									className={`bg-DBDBDB text-FF0000 font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Điểm thưởng</span>
								<input
									readOnly
									value={formatMoney(dataIncomeAgentLastMonth?.CommissionFee)}
									className={`bg-DBDBDB text-FF0000 font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Chi phí khuyến mãi</span>
								<input
									readOnly
									value={formatMoney(dataIncomeAgentLastMonth?.PromotionFee)}
									className={`bg-DBDBDB text-FF0000 font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Chi phí GiftCode</span>
								<input
									readOnly
									value={formatMoney(dataIncomeAgentLastMonth?.GiftCodeFee)}
									className={`bg-DBDBDB text-FF0000 font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Doanh thu âm kỳ trước</span>
								<input
									readOnly
									value={formatMoney(dataIncomeAgentLastMonth?.LastNegativeIncome)}
									className={`bg-DBDBDB text-FF0000 font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Doanh thu còn lại</span>
								<input
									readOnly
									value={formatMoney(dataIncomeAgentLastMonth?.LeftIncome)}
									className={`bg-DBDBDB ${
										dataIncomeAgentLastMonth?.LeftIncome < 0 ? "text-276196" : "text-FF0000"
									} font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Tỷ lệ hoa hồng</span>
								<input
									readOnly
									value={`${dataIncomeAgentLastMonth?.CommissionRate} %`}
									className={`bg-DBDBDB ${
										dataIncomeAgentLastMonth?.CommissionRate < 0 ? "text-FF0000" : "text-276196"
									} font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Doanh thu thực nhận</span>
								<input
									readOnly
									value={formatMoney(dataIncomeAgentLastMonth?.Income)}
									className={`bg-DBDBDB ${
										dataIncomeAgentLastMonth?.Income < 0 ? "text-FF0000" : "text-276196"
									} font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 pb-3">
								<span className="w6-12 text-right font-medium text-black">Trạng thái</span>
								<input
									readOnly
									value={dataIncomeAgentLastMonth?.Status ? "Đã Thanh Toán" : "Chưa Thanh Toán"}
									className="bg-DBDBDB text-black font-semibold rounded-lg border border-solid border-F5BC27 flex-1 px-2"
								/>
							</div>
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default AgencyReport;
