export const getDayOfWeek = (date) => {
  const dayOfWeek = new Date(date).getDay();
  switch (dayOfWeek) {
    case 0:
      return "Chủ Nhật";
    case 1:
      return "Thứ Hai";
    case 2:
      return "Thứ Ba";
    case 3:
      return "Thứ Tư";
    case 4:
      return "Thứ Năm";
    case 5:
      return "Thứ Sáu";
    case 6:
      return "Thứ Bảy";
    default:
      break;
  }
};

export function formatMoney(num = 0) {
  if (!num) return 0;
  const newNum = `${num}`.split(".");
  const num2 = newNum[1] ? `${newNum[1]}`.slice(0, 2) : ``;
  return `${newNum[0]}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (num2 ? `.${num2}` : ``);
}

export const formatUUID = (uuid = ``) => {
  return uuid.split("-")[0]
};

export function generateCode() {
  return (Math.random() + 1).toString(36).substring(2);
}

export const downPercentNumber = (num, percent) => {
  return parseFloat((num * (1 - percent / 100)).toFixed(2));
}