export const API_URL = "https://api.vibet88.fun/"; //vibet86 - casinovibet88
export const IMG_URL = "https://cdn.trondoi.net/"; //vibet86 - casinovibet88
export const IMG_MB = "https://cdn.trondoi.net/"; //vibet86 - casinovibet88

export const slideOptsHome = {
	initialSlide: 1,
	speed: 400,
};

export const LOTTERY_CENTRAL: any = {
	"Chủ Nhật": ["thuathienhue", "khanhhoa", "kontum"],
	"Thứ Hai": ["thuathienhue", "phuyen"],
	"Thứ Ba": ["daklak", "quangnam"],
	"Thứ Tư": ["danang", "khanhhoa"],
	"Thứ Năm": ["binhdinh", "quangtri", "quangbinh"],
	"Thứ Sáu": ["gialai", "ninhthuan"],
	"Thứ Bảy": ["danang", "quangngai", "daknong"],
	"Tất Cả": [
		"thuathienhue",
		"phuyen",
		"daklak",
		"quangnam",
		"danang",
		"khanhhoa",
		"binhdinh",
		"quangtri",
		"quangbinh",
		"gialai",
		"ninhthuan",
		"quangngai",
		"daknong",
		"kontum",
	],
};

export const LOTTERY_SOUTH: any = {
	"Chủ Nhật": ["tiengiang", "kiengiang", "dalat"],
	"Thứ Hai": ["hcm", "dongthap", "camau"],
	"Thứ Ba": ["vungtau", "baclieu"],
	"Thứ Tư": ["dongnai", "cantho", "soctrang"],
	"Thứ Năm": ["tayninh", "angiang", "binhthuan"],
	"Thứ Sáu": ["vinhlong", "binhduong", "travinh"],
	"Thứ Bảy": ["hcm", "binhphuoc", "haugiang"],
	"Tất Cả": [
		"hcm",
		"dongthap",
		"camau",
		"vungtau",
		"baclieu",
		"dongnai",
		"cantho",
		"soctrang",
		"tayninh",
		"angiang",
		"binhthuan",
		"vinhlong",
		"binhduong",
		"travinh",
		"binhphuoc",
		"haugiang",
		"tiengiang",
		"kiengiang",
		"dalat",
	],
};

export const LOTTERY_QUICK = ["sieutoc1s", "sieutoc45s", "sieutoc1p", "sieutoc1p5", "sieutoc2p", "sieutoc5p"];
export const LOTTERY_NORTH = ["mienbac", "mienbac18h25"];

export const LOTTERY_DISPLAY_NAME = {
	thuathienhue: "Thừa Thiên Huế",
	phuyen: "Phú Yên",
	daklak: "Đắk Lắk",
	quangnam: "Quảng Nam",
	danang: "Đà Nẵng",
	khanhhoa: "Khánh Hòa",
	binhdinh: "Bình Định",
	quangtri: "Quảng Trị",
	quangbinh: "Quảng Bình",
	gialai: "Gia Lai",
	ninhthuan: "Ninh Thuận",
	quangngai: "Quảng Ngãi",
	daknong: "Đắc Nông",
	kontum: "Kon Tum",
	hcm: "Hồ Chí Minh",
	dongthap: "Đồng Tháp",
	camau: "Cà Mau",
	vungtau: "Vũng Tàu",
	baclieu: "Bạc Liêu",
	dongnai: "Đồng Nai",
	cantho: "Cần Thơ",
	soctrang: "Sóc Trăng",
	tayninh: "Tây Ninh",
	angiang: "An Giang",
	binhthuan: "Bình Thuận",
	vinhlong: "Vĩnh Long",
	binhduong: "Bình Dương",
	travinh: "Trà Vinh",
	binhphuoc: "Bình Phước",
	haugiang: "Hậu Giang",
	tiengiang: "Tiền Giang",
	kiengiang: "Kiên Giang",
	dalat: "Đà Lạt",
	mienbac: "Miền Bắc",
	mienbac18h25: "Đặc Biệt 18H25",
	sieutoc1s: "Siêu Tốc 1 Giây",
	sieutoc45s: "Siêu Tốc 45 Giây",
	sieutoc1p: "Siêu Tốc 1 Phút",
	sieutoc1p5: "Siêu Tốc 1,5 Phút",
	sieutoc2p: "Siêu Tốc 2 Phút",
	sieutoc5p: "Siêu Tốc 5 Phút",
	live2p: "Siêu Tốc Livestream 2 Phút",
};

export const VISIBILITY_FILTERS = {
	ALL: "all",
	COMPLETED: "completed",
	INCOMPLETE: "incomplete",
};

export const INIT_REGISTER = {
	username: "",
	email: "",
	password: "",
	nickName: "",
	mobile: "",
	confirmPassword: "",
};
export const BET_STATUS = {
	Win: "Trúng",
	Lose: "Trượt",
	Cancel: "Huỷ",
	Waiting: "Chưa mở thưởng",
};

export const NUM_QUICK = [
	"00",
	"01",
	"02",
	"03",
	"04",
	"05",
	"06",
	"07",
	"08",
	"09",
	"10",
	"11",
	"12",
	"13",
	"14",
	"15",
	"16",
	"17",
	"18",
	"19",
	"20",
	"21",
	"22",
	"23",
	"24",
	"25",
	"26",
	"27",
	"28",
	"29",
	"30",
	"31",
	"32",
	"33",
	"34",
	"35",
	"36",
	"37",
	"38",
	"39",
	"40",
	"41",
	"42",
	"43",
	"44",
	"45",
	"46",
	"47",
	"48",
	"49",
	"50",
	"51",
	"52",
	"53",
	"54",
	"55",
	"56",
	"57",
	"58",
	"59",
	"60",
	"61",
	"62",
	"63",
	"64",
	"65",
	"66",
	"67",
	"68",
	"69",
	"70",
	"71",
	"72",
	"73",
	"74",
	"75",
	"76",
	"77",
	"78",
	"79",
	"80",
	"81",
	"82",
	"83",
	"84",
	"85",
	"86",
	"87",
	"88",
	"89",
	"90",
	"91",
	"92",
	"93",
	"94",
	"95",
	"96",
	"97",
	"98",
	"99",
];

export const QUICK_CHAN = [
	"00",
	"02",
	"04",
	"06",
	"08",
	"10",
	"12",
	"14",
	"16",
	"18",
	"20",
	"22",
	"24",
	"26",
	"28",
	"30",
	"32",
	"34",
	"36",
	"38",
	"40",
	"42",
	"44",
	"46",
	"48",
	"50",
	"52",
	"54",
	"56",
	"58",
	"60",
	"62",
	"64",
	"66",
	"68",
	"70",
	"72",
	"74",
	"76",
	"78",
	"80",
	"82",
	"84",
	"86",
	"88",
	"90",
	"92",
	"94",
	"96",
	"98",
];

export const QUICK_LE = [
	"01",
	"03",
	"05",
	"07",
	"09",
	"11",
	"13",
	"15",
	"17",
	"19",
	"21",
	"23",
	"25",
	"27",
	"29",
	"31",
	"33",
	"35",
	"37",
	"39",
	"41",
	"43",
	"45",
	"47",
	"49",
	"51",
	"53",
	"55",
	"57",
	"59",
	"61",
	"63",
	"65",
	"67",
	"69",
	"71",
	"73",
	"75",
	"77",
	"79",
	"81",
	"83",
	"85",
	"87",
	"89",
	"91",
	"93",
	"95",
	"97",
	"99",
];

export const QUICK_LON = [
	"50",
	"51",
	"52",
	"53",
	"54",
	"55",
	"56",
	"57",
	"58",
	"59",
	"60",
	"61",
	"62",
	"63",
	"64",
	"65",
	"66",
	"67",
	"68",
	"69",
	"70",
	"71",
	"72",
	"73",
	"74",
	"75",
	"76",
	"77",
	"78",
	"79",
	"80",
	"81",
	"82",
	"83",
	"84",
	"85",
	"86",
	"87",
	"88",
	"89",
	"90",
	"91",
	"92",
	"93",
	"94",
	"95",
	"96",
	"97",
	"98",
	"99",
];

export const QUICK_NHO = [
	"00",
	"01",
	"02",
	"03",
	"04",
	"05",
	"06",
	"07",
	"08",
	"09",
	"10",
	"11",
	"12",
	"13",
	"14",
	"15",
	"16",
	"17",
	"18",
	"19",
	"20",
	"21",
	"22",
	"23",
	"24",
	"25",
	"26",
	"27",
	"28",
	"29",
	"30",
	"31",
	"32",
	"33",
	"34",
	"35",
	"36",
	"37",
	"38",
	"39",
	"40",
	"41",
	"42",
	"43",
	"44",
	"45",
	"46",
	"47",
	"48",
	"49",
];

export const INIT_LOTTERY = {
	thuathienhue: {
		displayName: "Thừa Thiên Huế",
		drawTime: "17:10:00",
		dayOfWeek: [0, 6],
	},
	phuyen: {
		displayName: "Phú Yên",
		drawTime: "17:10:00",
		dayOfWeek: [0],
	},
	daklak: {
		displayName: "Đắk Lắk",
		drawTime: "17:10:00",
		dayOfWeek: [1],
	},
	quangnam: {
		displayName: "Quảng Nam",
		drawTime: "17:10:00",
		dayOfWeek: [1],
	},
	danang: {
		displayName: "Đà Nẵng",
		drawTime: "17:10:00",
		dayOfWeek: [2, 5],
	},
	khanhhoa: {
		displayName: "Khánh Hòa",
		drawTime: "17:10:00",
		dayOfWeek: [2, 6],
	},
	binhdinh: {
		displayName: "Bình Định",
		drawTime: "17:10:00",
		dayOfWeek: [3],
	},
	quangtri: {
		displayName: "Quảng Trị",
		drawTime: "17:10:00",
		dayOfWeek: [3],
	},
	quangbinh: {
		displayName: "Quảng Bình",
		drawTime: "17:10:00",
		dayOfWeek: [3],
	},
	gialai: {
		displayName: "Gia Lai",
		drawTime: "17:10:00",
		dayOfWeek: [4],
	},
	ninhthuan: {
		displayName: "Ninh Thuận",
		drawTime: "17:10:00",
		dayOfWeek: [4],
	},
	quangngai: {
		displayName: "Quảng Ngãi",
		drawTime: "17:10:00",
		dayOfWeek: [5],
	},
	daknong: {
		displayName: "Đắk Nông",
		drawTime: "17:10:00",
		dayOfWeek: [5],
	},
	kontum: {
		displayName: "Kon Tum",
		drawTime: "17:10:00",
		dayOfWeek: [6],
	},
	hcm: {
		displayName: "Hồ Chí Minh",
		drawTime: "16:10:00",
		dayOfWeek: [0, 5],
	},
	dongthap: {
		displayName: "Đồng Tháp",
		drawTime: "16:10:00",
		dayOfWeek: [0],
	},
	camau: {
		displayName: "Cà Mau",
		drawTime: "16:10:00",
		dayOfWeek: [0],
	},
	vungtau: {
		displayName: "Vũng Tàu",
		drawTime: "16:10:00",
		dayOfWeek: [1],
	},
	baclieu: {
		displayName: "Bạc Liêu",
		drawTime: "16:10:00",
		dayOfWeek: [1],
	},
	dongnai: {
		displayName: "Đồng Nai",
		drawTime: "16:10:00",
		dayOfWeek: [2],
	},
	cantho: {
		displayName: "Cần Thơ",
		drawTime: "16:10:00",
		dayOfWeek: [2],
	},
	soctrang: {
		displayName: "Sóc Trăng",
		drawTime: "16:10:00",
		dayOfWeek: [2],
	},
	tayninh: {
		displayName: "Tây Ninh",
		drawTime: "16:10:00",
		dayOfWeek: [3],
	},
	angiang: {
		displayName: "An Giang",
		drawTime: "16:10:00",
		dayOfWeek: [3],
	},
	binhthuan: {
		displayName: "Bình Thuận",
		drawTime: "16:10:00",
		dayOfWeek: [3],
	},
	vinhlong: {
		displayName: "Vĩnh Long",
		drawTime: "16:10:00",
		dayOfWeek: [4],
	},
	binhduong: {
		displayName: "Bình Dương",
		drawTime: "16:10:00",
		dayOfWeek: [4],
	},
	travinh: {
		displayName: "Trà Vinh",
		drawTime: "16:10:00",
		dayOfWeek: [4],
	},
	binhphuoc: {
		displayName: "Bình Phước",
		drawTime: "16:10:00",
		dayOfWeek: [5],
	},
	haugiang: {
		displayName: "Hậu Giang",
		drawTime: "16:10:00",
		dayOfWeek: [5],
	},
	tiengiang: {
		displayName: "Tiền Giang",
		drawTime: "16:10:00",
		dayOfWeek: [6],
	},
	kiengiang: {
		displayName: "Kiên Giang",
		drawTime: "16:10:00",
		dayOfWeek: [6],
	},
	dalat: {
		displayName: "Đà Lạt",
		drawTime: "16:10:00",
		dayOfWeek: [6],
	},
	mienbac: {
		displayName: "Miền Bắc",
		drawTime: "18:10:00",
		dayOfWeek: [0, 1, 2, 3, 4, 5, 6],
	},
	mienbac18h25: {
		displayName: "Đặc Biệt 18H25",
		drawTime: "18:25:00",
		dayOfWeek: [0, 1, 2, 3, 4, 5, 6],
	},
	sieutoc1s: {
		displayName: "Siêu Tốc 1 Giây",
		drawTime: "",
		dayOfWeek: [],
	},
	sieutoc45s: {
		displayName: "Siêu Tốc 45 Giây",
		drawTime: "",
		dayOfWeek: [],
	},
	sieutoc1p: {
		displayName: "Siêu Tốc 1 Phút",
		drawTime: "",
		dayOfWeek: [],
	},
	sieutoc1p5: {
		displayName: "Siêu Tốc 1,5 Phút",
		drawTime: "",
		dayOfWeek: [],
	},
	sieutoc2p: {
		displayName: "Siêu Tốc 2 Phút",
		drawTime: "",
		dayOfWeek: [],
	},
	sieutoc5p: {
		displayName: "Siêu Tốc 5 Phút",
		drawTime: "",
		dayOfWeek: [],
	},
	live2p: {
		displayName: "Siêu Tốc Livestream 2 Phút",
		drawTime: "",
		dayOfWeek: [],
	},
};

export const BET_TYPES = [
	{
		Id: 1,
		Name: "Bao Lô 2 Số",
		CodeName: "baolo2so",
		GroupName: "BAO LÔ",
		Note: "Đánh 2 chữ số cuối trong các giải.",
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		TextNN: "Lô",
	},
	{
		Id: 2,
		Name: "Lô 2 Số Đầu",
		CodeName: "lo2sodau",
		GroupName: "BAO LÔ",
		Note: "Đánh 2 chữ số đầu tiên từ giải Đặc biệt đến giải Sáu.",
		RateMB: 23,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMN: true,
		NotShowXSN: true,
		TextNN: "Lô",
	},
	{
		Id: 3,
		Name: "Lô 2 Số 1K",
		CodeName: "lo2so1k",
		GroupName: "BAO LÔ",
		Note: "Đánh 2 chữ số cuối trong các giải.",
		RateMB: 1,
		WinRateMB: 3649,
		Rate: 1,
		WinRate: 5445,
		Num: 2,
		Tabs: [1, 2, 0],
		TextNN: "Lô",
	},
	{
		Id: 4,
		Name: "Bao Lô 3 Số",
		CodeName: "baolo3so",
		GroupName: "BAO LÔ",
		Note: "Đánh 3 chữ số cuối trong các giải có 3 chữ số trở lên.",
		RateMB: 23,
		WinRateMB: 960,
		Rate: 17,
		WinRate: 960,
		Num: 3,
		Tabs: [1, 2, 0],
		TextNN: "Lô",
	},
	{
		Id: 5,
		Name: "Bao Lô 4 Số",
		CodeName: "baolo4so",
		GroupName: "BAO LÔ",
		Note: "Đánh 4 chữ số cuối trong các giải có 4 chữ số trở lên.",
		RateMB: 20,
		WinRateMB: 8880,
		Rate: 16,
		WinRate: 8880,
		Num: 4,
		Tabs: [1, 0],
		TextNN: "Lô",
	},
	{
		Id: 6,
		Name: "Lô Xiên 2",
		CodeName: "loxien2",
		GroupName: "LÔ XIÊN",
		Note: "Xiên 2 của 2 chữ số cuối trong các giải.",
		RateMB: 1,
		WinRateMB: 16,
		Rate: 1,
		WinRate: 28,
		Num: 2,
		Tabs: [1, 2],
		TextNN: "Xiên",
	},
	{
		Id: 7,
		Name: "Lô Xiên 3",
		CodeName: "loxien3",
		GroupName: "LÔ XIÊN",
		Note: "Xiên 3 của 2 chữ số cuối trong các giải.",
		RateMB: 1,
		WinRateMB: 65,
		Rate: 1,
		WinRate: 150,
		Num: 2,
		Tabs: [1, 2],
		TextNN: "Xiên",
	},
	{
		Id: 8,
		Name: "Lô Xiên 4",
		CodeName: "loxien4",
		GroupName: "LÔ XIÊN",
		Note: "Xiên 4 của 2 chữ số cuối trong các giải.",
		RateMB: 1,
		WinRateMB: 180,
		Rate: 1,
		WinRate: 750,
		Num: 2,
		Tabs: [1, 2],
		TextNN: "Xiên",
	},
	{
		Id: 9,
		Name: "Đề Đầu Giải Nhất",
		CodeName: "dedaugiainhat",
		GroupName: "ĐÁNH ĐỀ",
		Note: "Đánh 2 chữ số đầu trong giải nhất.",
		RateMB: 1,
		WinRateMB: 98,
		Rate: 1,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
	},
	{
		Id: 10,
		Name: "Đề Đặc Biệt",
		CodeName: "dedacbiet",
		GroupName: "ĐÁNH ĐỀ",
		Note: "Đánh 2 chữ số cuối trong giải đặc biệt.",
		RateMB: 1,
		WinRateMB: 99,
		Rate: 1,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
	},
	{
		Id: 11,
		Name: "Đề Đầu Đặc Biệt",
		CodeName: "dedaudacbiet",
		GroupName: "ĐÁNH ĐỀ",
		Note: "Đánh 2 chữ số đầu trong giải đặc biệt.",
		RateMB: 1,
		WinRateMB: 99,
		Rate: 1,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
	},
	{
		Id: 12,
		Name: "Đề Giải 7",
		CodeName: "degiai7",
		GroupName: "ĐÁNH ĐỀ",
		Note: "Đánh 2 chữ số cuối trong 4 giải của giải 7.",
		RateMB: 4,
		WinRateMB: 98,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMN: true,
		NotShowXSN: true,
	},
	{
		Id: 13,
		Name: "Đề Giải Nhất",
		CodeName: "degiainhat",
		GroupName: "ĐÁNH ĐỀ",
		Note: "Đánh 2 chữ số cuối trong giải nhất",
		RateMB: 1,
		WinRateMB: 98,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMN: true,
		NotShowXSN: true,
	},
	{
		Id: 14,
		Name: "Đầu",
		CodeName: "dau",
		GroupName: "ĐẦU ĐUÔI",
		Note: "Đánh 1 chữ số hàng chục của giải đặc biệt.",
		RateMB: 10,
		WinRateMB: 98,
		Rate: 10,
		WinRate: 98,
		Num: 1,
		Tabs: [0],
		NotShowXSN: true,
	},
	{
		Id: 15,
		Name: "Đuôi",
		CodeName: "duoi",
		GroupName: "ĐẦU ĐUÔI",
		Note: "Đánh 1 chữ số cuối của giải đặc biệt.",
		RateMB: 10,
		WinRateMB: 98,
		Rate: 10,
		WinRate: 98,
		Num: 1,
		Tabs: [0],
		NotShowXSN: true,
	},
	{
		Id: 16,
		Name: "3 Càng Đầu",
		CodeName: "_3cangdau",
		GroupName: "3 CÀNG",
		Note: "Đánh giải 7 (giải có 3 chữ số).",
		RateMB: 3,
		WinRateMB: 960,
		Rate: 1,
		WinRate: 960,
		Num: 3,
		Tabs: [1, 2, 0],
	},
	{
		Id: 17,
		Name: "3 Càng Đầu Đuôi",
		CodeName: "_3cangdauduoi",
		GroupName: "3 CÀNG",
		Note: "Đánh 3 chữ số cuối của giải đặc biệt và giải 7.",
		RateMB: 4,
		WinRateMB: 960,
		Rate: 2,
		WinRate: 960,
		Num: 3,
		Tabs: [1, 2, 0],
	},
	{
		Id: 18,
		Name: "3 Càng Đặc Biệt",
		CodeName: "_3cangdacbiet",
		GroupName: "3 CÀNG",
		Note: "Đánh 3 chữ số cuối của giải đặc biệt.",
		RateMB: 1,
		WinRateMB: 960,
		Rate: 1,
		WinRate: 960,
		Num: 3,
		Tabs: [1, 2, 0],
	},
	{
		Id: 19,
		Name: "3 Càng Giải Nhất",
		CodeName: "_3canggiainhat",
		GroupName: "3 CÀNG",
		Note: "Đánh 3 chữ số cuối trong giải nhất.",
		RateMB: 1,
		WinRateMB: 960,
		Rate: 1,
		WinRate: 960,
		Num: 3,
		Tabs: [1, 2, 0],
	},
	{
		Id: 20,
		Name: "4 Càng Đặc Biệt",
		CodeName: "_4cangdacbiet",
		GroupName: "4 CÀNG ĐẶC BIỆT",
		Note: "Đánh 4 chữ số cuối của giải đặc biệt.",
		RateMB: 1,
		WinRateMB: 8880,
		Rate: 1,
		WinRate: 8880,
		Num: 4,
		Tabs: [1, 0],
	},
	{
		Id: 21,
		Name: "Trượt Xiên 4",
		CodeName: "truotxien4",
		GroupName: "LÔ TRƯỢT",
		Note: "Trượt Xiên 4 của 2 chữ số cuối trong các giải.",
		RateMB: 1,
		WinRateMB: 2.3,
		Rate: 1,
		WinRate: 1.8,
		Num: 2,
		Tabs: [1, 2],
	},
	{
		Id: 22,
		Name: "Trượt Xiên 8",
		CodeName: "truotxien8",
		GroupName: "LÔ TRƯỢT",
		Note: "Trượt Xiên 8 của 2 chữ số cuối trong các giải.",
		RateMB: 1,
		WinRateMB: 8,
		Rate: 1,
		WinRate: 3.3,
		Num: 2,
		Tabs: [1, 2],
	},
	{
		Id: 23,
		Name: "Trượt Xiên 10",
		CodeName: "truotxien10",
		GroupName: "LÔ TRƯỢT",
		Note: "Trượt Xiên 10 của 2 chữ số cuối trong các giải.",
		RateMB: 1,
		WinRateMB: 12,
		Rate: 1,
		WinRate: 4.3,
		Num: 2,
		Tabs: [1, 2],
	},
	{
		Id: 24,
		Name: "Số Thường",
		CodeName: "sothuong",
		GroupName: "SỐ THƯỜNG",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 25,
		Name: "Trúng 4 Số",
		CodeName: "trung4so",
		GroupName: "NHIỀU SỐ",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 26,
		Name: "Trúng 3 Số",
		CodeName: "trung3so",
		GroupName: "NHIỀU SỐ",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 27,
		Name: "Trúng 2 Số",
		CodeName: "trung2so",
		GroupName: "NHIỀU SỐ",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 28,
		Name: "3 Trúng 2",
		CodeName: "_3trung2",
		GroupName: "NHIỀU SỐ",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 29,
		Name: "Trượt Xiên 5",
		CodeName: "truotxien5mega",
		GroupName: "LÔ TRƯỢT",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 30,
		Name: "Trượt Xiên 6",
		CodeName: "truotxien6mega",
		GroupName: "LÔ TRƯỢT",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 31,
		Name: "Trượt Xiên 7",
		CodeName: "truotxien7mega",
		GroupName: "LÔ TRƯỢT",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 32,
		Name: "Trượt Xiên 8",
		CodeName: "truotxien8mega",
		GroupName: "LÔ TRƯỢT",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 33,
		Name: "Trượt Xiên 9",
		CodeName: "truotxien9mega",
		GroupName: "LÔ TRƯỢT",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 34,
		Name: "Trượt Xiên 10",
		CodeName: "truotxien10mega",
		GroupName: "LÔ TRƯỢT",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 35,
		Name: "Chọn 5 Ăn 1",
		CodeName: "chon5an1",
		GroupName: "CHỌN 1",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 36,
		Name: "Chọn 6 Ăn 1",
		CodeName: "chon6an1",
		GroupName: "CHỌN 1",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 37,
		Name: "Chọn 7 Ăn 1",
		CodeName: "chon7an1",
		GroupName: "CHỌN 1",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 38,
		Name: "Chọn 8 Ăn 1",
		CodeName: "chon8an1",
		GroupName: "CHỌN 1",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 39,
		Name: "Chọn 9 Ăn 1",
		CodeName: "chon9an1",
		GroupName: "CHỌN 1",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 40,
		Name: "Chọn 10 Ăn 1",
		CodeName: "chon10an1",
		GroupName: "CHỌN 1",
		Note: null,
		RateMB: 27,
		WinRateMB: 985,
		Rate: 18,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
		NotShowMN: true,
	},
	{
		Id: 41,
		Name: "Đề Đầu Đuôi",
		CodeName: "dedauduoi",
		GroupName: "ĐÁNH ĐỀ",
		Note: "Đánh 2 chữ số giải đặc biệt và giải 8.",
		RateMB: 27,
		WinRateMB: 985,
		Rate: 2,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
	},
	{
		Id: 42,
		Name: "Đề Đầu",
		CodeName: "dedau",
		GroupName: "ĐÁNH ĐỀ",
		Note: "Đánh giải 8.",
		RateMB: 27,
		WinRateMB: 985,
		Rate: 1,
		WinRate: 98,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
	},
	{
		Id: 43,
		Name: "Đề Đặc Biệt 18H25",
		CodeName: "dedacbiet18h25",
		GroupName: "ĐÁNH ĐỀ",
		Note: "Đánh 2 chữ số cuối trong giải đặc biệt.",
		RateMB: 1,
		WinRateMB: 85,
		Rate: 1,
		WinRate: 85,
		Num: 2,
		Tabs: [1, 2, 0],
		NotShowMB: true,
	},
	{
		Id: 44,
		Name: "Xiên chéo 2 đài",
		CodeName: "xiencheo2dai",
		GroupName: "LÔ XIÊN",
		Note: "Đánh xiên 2 đài.",
		NotShowMB: true,
		RateMB: 1,
		WinRateMB: 3100,
		Rate: 1,
		WinRate: 3100,
		Num: 2,
		Tabs: [1, 2],
		NotShowXSN: true,
	},
];

export const TITLE_NUM: any = {
	0: "Đơn vị",
	1: "Chục",
	2: "Trăm",
	3: "Nghìn",
};


export const INDEX_PROMOTION = {
	28: 1,
	36: 2,
	30: 3,
	7: 4,
	32: 5,
};

export const TITLE_BALANCE_LOG: any = {
	"Đặt cược": "Xổ số",
	"Cộng tiền cược": "Xổ số",
	"Chuyển điểm": "Tài khoản chính",
};
