import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./auth/authRedux";
import { accountsSlice } from "./accounts/accountsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  accounts: accountsSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
