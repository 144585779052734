import React, { useState, useEffect } from "react";
import {
	IonContent,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonIcon,
	IonButton,
	IonLabel,
	IonInput,
	IonSelect,
	IonSelectOption,
	IonImg,
	IonModal,
	IonGrid,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { chevronForward } from "ionicons/icons";
import { getMembersDownline, getMemberDownlineDetail } from "../redux/auth/authCrud";
import { formatMoney } from "../util";
import moment from "moment";
import { defaultsDeep, filter, orderBy, includes } from "lodash";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";

import "./Agency.css";
import { IMG_MB } from "../constants";

const AgencyMemberSearch: React.FC = () => {
	const history = useHistory();
	const [dataSearch, setDataSearch] = useState({ UserName: "", Arrange: "" });

	const [dataMembersDownline, setDataMembersDownline] = useState([]);
	const [dataMembersDownlineSearch, setDataMembersDownlineSearch] = useState<any>(null);
	const [indexMember, setIndexMember] = useState<any>(null);
	const [dataMemberDownlineDetail, setDataMemberDownlineDetail] = useState<any>(null);

	//paginate
	const [currentItems, setCurrentItems] = useState([]);

	useEffect(() => {
		const tempData = dataMembersDownlineSearch ? dataMembersDownlineSearch : dataMembersDownline;
		setCurrentItems(tempData);
	}, [dataMembersDownline, dataMembersDownlineSearch]);

	const GetMembersDownline = async () => {
		const res = await getMembersDownline();
		if (res && res.data) {
			setDataMembersDownline(res.data);
		}
	};

	const _searchMember = () => {
		let tempSearch = null;
		if (dataSearch.UserName) {
			tempSearch = filter(dataMembersDownline, (item) => includes(item["UserName"], dataSearch?.UserName));
		}

		switch (dataSearch.Arrange) {
			case "0":
				tempSearch = orderBy(tempSearch ? tempSearch : dataMembersDownline, ["Credits"], ["desc"]);
				break;
			case "1":
				tempSearch = orderBy(tempSearch ? tempSearch : dataMembersDownline, ["Credits"], ["asc"]);
				break;
			case "2":
				tempSearch = orderBy(tempSearch ? tempSearch : dataMembersDownline, ["CreatedTime"], ["desc"]);
				break;
			case "3":
				tempSearch = orderBy(tempSearch ? tempSearch : dataMembersDownline, ["CreatedTime"], ["asc"]);
				break;
			case "4":
				tempSearch = orderBy(tempSearch ? tempSearch : dataMembersDownline, ["LastLogin"], ["desc"]);
				break;
			case "5":
				tempSearch = filter(tempSearch ? tempSearch : dataMembersDownline, ["isActive", false]);
				break;
			case "6":
				tempSearch = filter(tempSearch ? tempSearch : dataMembersDownline, ["isActive", true]);
				break;

			default:
				break;
		}

		setDataMembersDownlineSearch(tempSearch);
	};

	useEffect(() => {
		GetMembersDownline();
	}, []);

	return (
		<IonPage className="app-agency-member-search">
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/agency")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Quản Lý Thành Viên
					</IonButton>
				</div>
			</div>
			<IonContent className="agency-member-list">
				<div className="af-wrapper">
					<div className="af-item">
						<div className="flex agency-member-list-item">
							<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/icon-agency-account.png`} />
							<IonLabel className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Tài khoản</IonLabel>
						</div>
						<IonInput
							clearInput={true}
							type="text"
							value={dataSearch?.UserName}
							onIonChange={(e) => {
								setDataSearch(
									defaultsDeep(
										{
											UserName: e.detail.value,
										},
										dataSearch
									)
								);
							}}
							className="agency-member-input ng-untouched ng-pristine ng-valid ion-untouched ion-pristine ion-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated"
						></IonInput>
					</div>
					<div className="af-item">
						<div className="flex agency-member-list-item">
							<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/icon-agency-filter.png`} />
							<IonLabel className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Sắp xếp theo</IonLabel>
						</div>
						<IonSelect
							cancelText="Huỷ"
							okText="Chọn"
							className="agency-member-input ng-pristine ng-valid ios hydrated ng-touched ion-pristine ion-valid ion-touched"
							role="combobox"
							aria-haspopup="dialog"
							aria-expanded="false"
							aria-labelledby="ion-sel-1-lbl"
							value={dataSearch?.Arrange}
							onIonChange={(e) => {
								setDataSearch(
									defaultsDeep(
										{
											Arrange: e.detail.value,
										},
										dataSearch
									)
								);
							}}
						>
							<IonSelectOption role="option" id="ion-selopt-1" className="ios hydrated" value="0">
								Số dư nhiều nhất
							</IonSelectOption>
							<IonSelectOption role="option" id="ion-selopt-2" className="ios hydrated" value="1">
								Số dư ít nhất
							</IonSelectOption>
							<IonSelectOption role="option" id="ion-selopt-3" className="ios hydrated" value="2">
								Thời gian tạo mới nhất
							</IonSelectOption>
							<IonSelectOption role="option" id="ion-selopt-4" className="ios hydrated" value="3">
								Thời gian tạo cũ nhất
							</IonSelectOption>
							<IonSelectOption role="option" id="ion-selopt-5" className="ios hydrated" value="4">
								Đăng nhập cuối
							</IonSelectOption>
							<IonSelectOption role="option" id="ion-selopt-6" className="ios hydrated" value="5">
								Trạng thái bị khoá
							</IonSelectOption>
							<IonSelectOption role="option" id="ion-selopt-6" className="ios hydrated" value="6">
								Trạng thái bình thường
							</IonSelectOption>
						</IonSelect>
					</div>
					<IonButton
						className="af-submit-btn ios button button-block button-solid ion-activatable ion-focusable hydrated"
						expand="block"
						onClick={() => _searchMember()}
					>
						Tìm kiếm
					</IonButton>
				</div>
				<div className="p-10">
					<table className="sic-table">
						<thead>
							<tr>
								<th>Tài Khoản</th>
								<th>Số Dư</th>
								<th colSpan={2}> Tác Vụ</th>
							</tr>
						</thead>
						<tbody>
							{currentItems.map((item: any, index) => {
								return (
									<React.Fragment key={index}>
										<tr>
											<td>
												<span className="text-title">{item?.UserName}</span>
												<br />
												{item?.NickName}
											</td>
											<td>
												<span className="text-amount">{formatMoney(item?.Credits)}</span>
											</td>
											<td className="p-1">
												<button
													className="cursor-pointer bg-[#F5BC27] text-black p-1 rounded-lg font-normal"
													onClick={async () => {
														const res = await getMemberDownlineDetail(item?.UserName);
														if (res.data && res.data.Data) {
															setDataMemberDownlineDetail(res.data.Data);
														}
													}}
												>
													Xem chi tiết
												</button>
											</td>
											<td>
												<IonIcon slot="end" role="img" icon={chevronForward} onClick={() => setIndexMember(indexMember !== index ? index : null)}></IonIcon>
											</td>
										</tr>
										{indexMember === index && (
											<>
												<tr>
													<td colSpan={2}>Ngày tạo tài khoản</td>
													<td colSpan={2}>{moment(item?.CreatedTime).format("DD-MM-YYYY HH:mm:ss")}</td>
												</tr>
												<tr>
													<td colSpan={2}>Đăng nhập lần cuối</td>
													<td colSpan={2}>{moment(item?.LastLogin).format("DD-MM-YYYY HH:mm:ss")}</td>
												</tr>
												<tr>
													<td colSpan={2}>Trạng thái tài khoản</td>
													<td colSpan={2}>{item?.isActiveFormat}</td>
												</tr>
												<tr>
													<td colSpan={2}>Cược tháng này</td>
													<td colSpan={2}>
														<span className="text-amount">{formatMoney(item?.BetThisMonth)}</span>
													</td>
												</tr>
												<tr>
													<td colSpan={2}>Cược tháng trước</td>
													<td colSpan={2}>
														<span className="text-amount">{formatMoney(item?.BetLastMonth)}</span>
													</td>
												</tr>
											</>
										)}
									</React.Fragment>
								);
							})}
						</tbody>
					</table>
				</div>
			</IonContent>
			<IonModal
				id="vibet88-modal"
				className="h-70"
				isOpen={dataMemberDownlineDetail ? true : false}
				onIonModalDidDismiss={() => setDataMemberDownlineDetail(null)}
			>
				<IonContent className="modal-promotion-content">
					<div className="header">
						<IonToolbar>
							<IonTitle>XEM CHI TIẾT THÔNG TIN THÀNH VIÊN</IonTitle>
							<IonButtons slot="end">
								<IonButton color="light" onClick={() => setDataMemberDownlineDetail(null)}>
									<IonImg className="icon-close" src={`${IMG_MB}assets/icon/close-modal.png`} />
								</IonButton>
							</IonButtons>
						</IonToolbar>
						<div className="promotion-header text-center">
							<span className="text-[#F5BC27] text-[15px] uppercase">{dataMemberDownlineDetail?.UserName}</span>
						</div>
					</div>
					<IonGrid className="ios ion-grid hydrated pt-70px">
						<div className="flex flex-col h-full overflow-x-auto gap-3 p-3">
							<div className="flex flex-1 flex-col w-full">
								<div className="flex-1 bg-[#EEEEEE] rounded-md border-[#D9D9D9] border p-3 w-full">
									<span className="text-[#2A5B84] text-[15px] font-bold">Thông tin tài khoản</span>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Tên đăng nhập</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{dataMemberDownlineDetail?.UserName}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Họ và tên</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{dataMemberDownlineDetail?.NickName}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Ngày đăng ký</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{dataMemberDownlineDetail?.CreatedTime && moment(dataMemberDownlineDetail?.CreatedTime).format("DD/MM/YYYY HH:mm:ss")}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Đăng nhập cuối</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{dataMemberDownlineDetail?.LastLogin && moment(dataMemberDownlineDetail?.LastLogin).format("DD/MM/YYYY HH:mm:ss")}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Tổng số tiền cược</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{formatMoney(dataMemberDownlineDetail?.TotalBetAmount)}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Tiền cược hợp lệ</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{formatMoney(dataMemberDownlineDetail?.TotalTurnOver)}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Tổng thắng thua</span>
									</div>
									<div className={`flex-1 font-semibold p-2 ${dataMemberDownlineDetail?.WinLose < 0 ? "text-[#FF0000]" : ""}`}>
										<span>{formatMoney(dataMemberDownlineDetail?.WinLose)}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Số dư hiện tại</span>
									</div>
									<div className="flex-1 font-semibold p-2">
										<span>{formatMoney(dataMemberDownlineDetail?.Credits)}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Trạng thái</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{dataMemberDownlineDetail?.IsActiveFormat}</span>
									</div>
								</div>
							</div>
							<div className="flex flex-1 flex-col w-full">
								<div className="flex-1 bg-[#EEEEEE] rounded-md border-[#D9D9D9] border p-3 w-full">
									<span className="text-[#2A5B84] text-[15px] font-bold">Thông tin nạp tiền</span>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Thời gian nạp tiền lần đầu</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{dataMemberDownlineDetail?.FirstDepositAt && moment(dataMemberDownlineDetail?.FirstDepositAt).format("DD/MM/YYYY HH:mm:ss")}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Số tiền nạp lần đầu</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{dataMemberDownlineDetail?.FirstDepositAt && formatMoney(dataMemberDownlineDetail?.FirstDepositAmount)}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Thời gian nạp tiền mới nhất</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{dataMemberDownlineDetail?.LastDepositAt && moment(dataMemberDownlineDetail?.LastDepositAt).format("DD/MM/YYYY HH:mm:ss")}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Số tiền nạp mới nhất</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{dataMemberDownlineDetail?.LastDepositAmount && formatMoney(dataMemberDownlineDetail?.LastDepositAmount)}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Tổng số lần nạp tiền</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{dataMemberDownlineDetail?.TotalDepositCount}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Tổng số tiền nạp</span>
									</div>
									<div className="flex-1 font-semibold p-2">
										<span>{dataMemberDownlineDetail?.TotalDepositAmount && formatMoney(dataMemberDownlineDetail?.TotalDepositAmount)}</span>
									</div>
								</div>
							</div>
							<div className="flex flex-1 flex-col w-full">
								<div className="flex-1 bg-[#EEEEEE] rounded-md border-[#D9D9D9] border p-3 w-full">
									<span className="text-[#2A5B84] text-[15px] font-bold">Thông tin rút tiền</span>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Thời gian rút tiền mới nhất</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{dataMemberDownlineDetail?.LastWithdrawAt && moment(dataMemberDownlineDetail?.LastWithdrawAt).format("DD/MM/YYYY HH:mm:ss")}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Số tiền rút mới nhất</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{dataMemberDownlineDetail?.LastWithdrawAmount && formatMoney(dataMemberDownlineDetail?.LastWithdrawAmount)}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Số lần rút tiền</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{dataMemberDownlineDetail?.TotalWithdrawCount}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Tổng số tiền đã rút</span>
									</div>
									<div className="flex-1 font-semibold p-2">
										<span>{dataMemberDownlineDetail?.TotalWithdrawAmount && formatMoney(dataMemberDownlineDetail?.TotalWithdrawAmount)}</span>
									</div>
								</div>
							</div>
							<div className="flex flex-1 flex-col w-full">
								<div className="flex-1 bg-[#EEEEEE] rounded-md border-[#D9D9D9] border p-3 w-full">
									<span className="text-[#2A5B84] text-[15px] font-bold">Thông tin khuyến mãi</span>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Số tiền khuyến mãi đã nhận</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{formatMoney(dataMemberDownlineDetail?.TotalPromotionAmount)}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Giflcode đã nhận</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{formatMoney(dataMemberDownlineDetail?.TotalGiftCodeAmount)}</span>
									</div>
								</div>
								<div className="flex bg-[#F4F4F4] mx-1 text-[14px] text-black border-[#D9D9D9] border border-t-0 w-full">
									<div className="flex-1 font-semibold border-[#D9D9D9] border-r p-2 text-right">
										<span>Số tiền khuyến mãi đã nhận</span>
									</div>
									<div className="flex-1 font-medium p-2">
										<span>{formatMoney(dataMemberDownlineDetail?.TotalRewardAmount)}</span>
									</div>
								</div>
							</div>
						</div>
					</IonGrid>
				</IonContent>
			</IonModal>
		</IonPage>
	);
};

export default AgencyMemberSearch;
