import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonIcon, IonButton, IonButtons } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";
import { getMemberStatistic } from "../redux/auth/authCrud";
import { formatMoney } from "../util";
import moment from "moment";
import { useSelector, shallowEqual } from "react-redux";

import "./Agency.css";

const BetReport: React.FC = () => {
	const history = useHistory();

	const { userInfo } = useSelector(
		(state: any) => ({
			userInfo: state.auth.user,
		}),
		shallowEqual
	);

	const [dataMemberStatistic, setDataMemberStatistic] = useState<any>(null);
	const [dateStatistic, setDateStatistic] = useState({
		type: 1,
		fromTime: moment().format("YYYY-MM-DD 00:00:00"),
		toTime: moment().format("YYYY-MM-DD 23:59:59"),
	});

	const GetMemberStatistic = async (playerId: any, startTime: any, endTime: any) => {
		const res = await getMemberStatistic(playerId, startTime, endTime);
		if (res && res.data) {
			setDataMemberStatistic(res.data);
		}
	};

	useEffect(() => {
		if (userInfo) {
			GetMemberStatistic(userInfo?.Id, dateStatistic.fromTime, dateStatistic.toTime);
		}
	}, [userInfo, dateStatistic]);

	return (
		<IonPage>
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/account")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Thống Kê Đặt Cược
					</IonButton>
				</div>
			</div>
			<IonContent className="app-agency">
				<div className="flex gap-3 p-4 flex-col">
					<div className="w-full flex flex-col">
						<div className="w-full">
							<div className="sic-title-page">
								<span> Thống kê đặt cược </span>
								<hr />
							</div>
						</div>
						<div className="border w-full p-3 border-solid border-F5BC27  rounded-2xl bg-[#EAEAEA]">
							<IonButtons slot="primary" className="">
								<IonButton
									className={`${dateStatistic.type === 1 ? "btn-report-active" : "btn-report"} w-4/12`}
									fill="solid"
									onClick={() =>
										setDateStatistic({
											type: 1,
											fromTime: moment().format("YYYY-MM-DD 00:00:00"),
											toTime: moment().format("YYYY-MM-DD 23:59:59"),
										})
									}
								>
									Hôm Nay
								</IonButton>
								<IonButton
									fill="solid"
									className={`${dateStatistic.type === 2 ? "btn-report-active" : "btn-report"} w-4/12`}
									onClick={() =>
										setDateStatistic({
											type: 2,
											fromTime: moment().subtract(1, "day").format("YYYY-MM-DD 00:00:00"),
											toTime: moment().subtract(1, "day").format("YYYY-MM-DD 23:59:59"),
										})
									}
								>
									Hôm Qua
								</IonButton>
								<IonButton
									fill="solid"
									className={`${dateStatistic.type === 3 ? "btn-report-active" : "btn-report"} w-4/12`}
									onClick={() =>
										setDateStatistic({
											type: 3,
											fromTime: moment().startOf("weeks").format("YYYY-MM-DD 00:00:00"),
											toTime: moment().endOf("weeks").format("YYYY-MM-DD 23:59:59"),
										})
									}
								>
									Tuần Này
								</IonButton>
							</IonButtons>
							<IonButtons slot="primary" className="justify-content-center pt-10 pb-10">
								<IonButton
									className={`${dateStatistic.type === 4 ? "btn-report-active" : "btn-report"} w-4/12`}
									fill="solid"
									onClick={() =>
										setDateStatistic({
											type: 4,
											fromTime: moment().subtract(1, "weeks").startOf("weeks").format("YYYY-MM-DD 00:00:00"),
											toTime: moment().subtract(1, "weeks").endOf("weeks").format("YYYY-MM-DD 23:59:59"),
										})
									}
								>
									Tuần Trước
								</IonButton>
								<IonButton
									fill="solid"
									className={`${dateStatistic.type === 5 ? "btn-report-active" : "btn-report"} w-4/12`}
									onClick={() =>
										setDateStatistic({
											type: 5,
											fromTime: moment().startOf("months").format("YYYY-MM-DD 00:00:00"),
											toTime: moment().endOf("months").format("YYYY-MM-DD 23:59:59"),
										})
									}
								>
									Tháng Này
								</IonButton>
							</IonButtons>
							<div className="flex gap-3 text-14 item-report">
								<span className="w6-12 text-left font-medium text-black title">Số tiền đã nạp</span>
								<input
									readOnly
									value={formatMoney(dataMemberStatistic?.TotalDeposit)}
									className={`${
										dataMemberStatistic?.TotalDeposit > 0 ? "text-black" : "text-FF0000"
									} bg-transparent text-right font-semibold border-0 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 item-report">
								<span className="w6-12 text-left font-medium text-black title">Số tiền đã rút</span>
								<input
									readOnly
									value={formatMoney(dataMemberStatistic?.TotalWithdraw)}
									className={`${
										dataMemberStatistic?.TotalWithdraw > 0 ? "text-black" : "text-FF0000"
									} bg-transparent text-right font-semibold border-0 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 item-report">
								<span className="w6-12 text-left font-medium text-black title">Khuyến mãi đã nhận</span>
								<input
									readOnly
									value={formatMoney(dataMemberStatistic?.TotalReceivedPromotions)}
									className={`${
										dataMemberStatistic?.TotalReceivedPromotions > 0 ? "text-black" : "text-FF0000"
									} bg-transparent text-right font-semibold border-0 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 item-report">
								<span className="w6-12 text-left font-medium text-black title">GiflCode đã nhận</span>
								<input
									readOnly
									value={formatMoney(dataMemberStatistic?.TotalReceivedGiftCode)}
									className={`${
										dataMemberStatistic?.TotalReceivedGiftCode > 0 ? "text-black" : "text-FF0000"
									} bg-transparent text-right font-semibold border-0 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 item-report">
								<span className="w6-12 text-left font-medium text-black title">Điểm thưởng đã nhận</span>
								<input
									readOnly
									value={formatMoney(dataMemberStatistic?.TotalBetRefund)}
									className={`${
										dataMemberStatistic?.TotalBetRefund > 0 ? "text-black" : "text-FF0000"
									} bg-transparent text-right font-semibold border-0 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 item-report">
								<span className="w6-12 text-left font-medium text-black title">Hoa hồng đã nhận</span>
								<input
									readOnly
									value={formatMoney(dataMemberStatistic?.TotalReceivedCommision)}
									className={`${
										dataMemberStatistic?.TotalReceivedCommision > 0 ? "text-black" : "text-FF0000"
									} bg-transparent text-right font-semibold border-0 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 item-report">
								<span className="w6-12 text-left font-medium text-black title">Tổng số đơn cược</span>
								<input
									readOnly
									value={formatMoney(dataMemberStatistic?.TotalBetCount)}
									className={`${
										dataMemberStatistic?.TotalBetCount > 0 ? "text-black" : "text-FF0000"
									} bg-transparent text-right font-semibold border-0 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 item-report">
								<span className="w6-12 text-left font-medium text-black title">Tổng số tiền cược</span>
								<input
									readOnly
									value={formatMoney(dataMemberStatistic?.TotalBet)}
									className={`${dataMemberStatistic?.TotalBet > 0 ? "text-black" : "text-FF0000"} bg-transparent text-right font-semibold border-0 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 item-report">
								<span className="w6-12 text-left font-medium text-black title">Tổng thắng / thua</span>
								<input
									readOnly
									value={formatMoney(dataMemberStatistic?.TotalWinlose)}
									className={`${
										dataMemberStatistic?.TotalWinlose > 0 ? "text-black" : "text-FF0000"
									} bg-transparent text-right font-semibold border-0 flex-1 px-2`}
								/>
							</div>
						</div>
					</div>
					<div className="w-full flex flex-col">
						<div className="w-full">
							<div className="sic-title-page">
								<span> Thống kê theo game </span>
								<hr />
							</div>
						</div>
						<div className="border w-full p-3 border-solid border-F5BC27 rounded-2xl bg-[#EAEAEA]">
							<div className="flex gap-3 text-14 item-report">
								<span className="w6-12 text-left font-medium text-black title">Thống kê Xổ Số</span>
								<input
									readOnly
									value={formatMoney(dataMemberStatistic?.LotteryWinlose)}
									className={`${
										dataMemberStatistic?.LotteryWinlose > 0 ? "text-black" : "text-FF0000"
									} bg-transparent text-right font-semibold border-0 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 item-report">
								<span className="w6-12 text-left font-medium text-black title">Tống kê Casino Live</span>
								<input
									readOnly
									value={formatMoney(dataMemberStatistic?.CasinoWinlose)}
									className={`${
										dataMemberStatistic?.CasinoWinlose > 0 ? "text-black" : "text-FF0000"
									} bg-transparent text-right font-semibold border-0 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 item-report">
								<span className="w6-12 text-left font-medium text-black title">Thống kê Thể Thao</span>
								<input
									readOnly
									value={formatMoney(dataMemberStatistic?.SportWinlose)}
									className={`${
										dataMemberStatistic?.SportWinlose > 0 ? "text-black" : "text-FF0000"
									} bg-transparent text-right font-semibold border-0 flex-1 px-2`}
								/>
							</div>
							<div className="flex gap-3 text-14 item-report">
								<span className="w6-12 text-left font-medium text-black title">Thống kê Nổ Hũ</span>
								<input
									readOnly
									value={formatMoney(dataMemberStatistic?.NohuWinlose)}
									className={`${
										dataMemberStatistic?.NohuWinlose > 0 ? "text-black" : "text-FF0000"
									} bg-transparent text-right font-semibold border-0 flex-1 px-2`}
								/>
							</div>
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default BetReport;
