import React, { useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonImg, IonLabel, IonList, IonItem, IonIcon, IonRippleEffect } from "@ionic/react";
import { useSelector, shallowEqual, connect } from "react-redux";
import { chevronForward } from "ionicons/icons";
import * as auth from "../redux/auth/authRedux";
import SlideHome from "../components/SlideHome";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";

import "./Account.css";
import { IMG_MB } from "../constants";

const Account: React.FC = (props: any) => {
	const history = useHistory();

	const { authToken } = useSelector(
		(state: any) => ({
			authToken: state.auth.authToken,
		}),
		shallowEqual
	);

	useLayoutEffect(() => {
		if (!authToken) {
			window.location.href = "/home?login=true";
		}
	}, [authToken]);

	if (!authToken) return <IonImg className="page-loading" src={`${IMG_MB}assets/v2/loading.webp`} />;

	return (
		<IonPage className="app-account">
			<div className="app-home homepage">
				<HeaderHome />
				<SlideHome />
				<QuickOptions />
				<div className="sic-title-page">
					<span> Thông Tin Tài Khoản </span>
					<hr />
				</div>
			</div>
			<IonContent className="ion-content content-account-page">
				<IonList className="ion-list ng-tns-c32-17 ng-star-inserted ios list-ios hydrated">
					<IonItem
						className="ion-item pl-2 ion-activatable ng-tns-c32-17 ng-star-inserted item-label item ios in-list ion-focusable hydrated"
						onClick={() => history.push("/information")}
					>
						<div _nghost-mri-c5="" className="mr-3 ng-tns-c32-17">
							<IonImg
								className="ion-icon icon-start ng-tns-c32-17 ng-star-inserted ios hydrated"
								slot="start"
								role="img"
								src={`${IMG_MB}assets/account/taikhoan.png`}
							></IonImg>
						</div>
						<IonIcon slot="end" className="ng-tns-c32-17 ios hydrated" role="img" icon={chevronForward}></IonIcon>
						<div _nghost-mri-c5="" className="ng-tns-c32-17">
							<IonLabel className="ng-tns-c32-17 ng-star-inserted sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
								<h2 className="ng-tns-c32-17">Tài khoản cá nhân</h2>
							</IonLabel>
						</div>
						<IonRippleEffect className="ng-tns-c32-17 ios hydrated" role="presentation"></IonRippleEffect>
					</IonItem>
					<IonItem
						className="ion-item pl-2 ion-activatable ng-tns-c32-17 ng-star-inserted item-label item ios in-list ion-focusable hydrated"
						onClick={() => history.push("/reward-points")}
					>
						<div _nghost-mri-c5="" className="mr-3 ng-tns-c32-17">
							<IonImg
								className="ion-icon icon-start ng-tns-c32-17 ng-star-inserted ios hydrated"
								slot="start"
								role="img"
								src={`${IMG_MB}assets/account/dongbodiem.png`}
							></IonImg>
						</div>
						<IonIcon slot="end" className="ng-tns-c32-17 ios hydrated" role="img" icon={chevronForward}></IonIcon>
						<div _nghost-mri-c5="" className="ng-tns-c32-17">
							<IonLabel className="ng-tns-c32-17 ng-star-inserted sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
								<h2 className="ng-tns-c32-17">Điểm thưởng</h2>
							</IonLabel>
						</div>
						<IonRippleEffect className="ng-tns-c32-17 ios hydrated" role="presentation"></IonRippleEffect>
					</IonItem>
					<IonItem
						className="ion-item pl-2 ion-activatable ng-tns-c32-17 ng-star-inserted item-label item ios in-list ion-focusable hydrated"
						onClick={() => history.push("/collect-all-credits")}
					>
						<div _nghost-mri-c5="" className="mr-3 ng-tns-c32-17">
							<IonImg
								className="ion-icon icon-start ng-tns-c32-17 ng-star-inserted ios hydrated"
								slot="start"
								role="img"
								src={`${IMG_MB}assets/account/dongbodiem.png`}
							></IonImg>
						</div>
						<IonIcon slot="end" className="ng-tns-c32-17 ios hydrated" role="img" icon={chevronForward}></IonIcon>
						<div _nghost-mri-c5="" className="ng-tns-c32-17">
							<IonLabel className="ng-tns-c32-17 ng-star-inserted sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
								<h2 className="ng-tns-c32-17">Đồng bộ quỹ điểm</h2>
							</IonLabel>
						</div>
						<IonRippleEffect className="ng-tns-c32-17 ios hydrated" role="presentation"></IonRippleEffect>
					</IonItem>
					<IonItem
						className="ion-item pl-2 ion-activatable ng-tns-c32-17 ng-star-inserted item-label item ios in-list ion-focusable hydrated"
						onClick={() => history.push("/agency-balance")}
					>
						<div _nghost-mri-c5="" className="mr-3 ng-tns-c32-17">
							<IonImg
								className="ion-icon icon-start ng-tns-c32-17 ng-star-inserted ios hydrated"
								slot="start"
								role="img"
								src={`${IMG_MB}assets/account/biendongsodu.png`}
							></IonImg>
						</div>
						<IonIcon slot="end" className="ng-tns-c32-17 ios hydrated" role="img" icon={chevronForward}></IonIcon>
						<div _nghost-mri-c5="" className="ng-tns-c32-17">
							<IonLabel className="ng-tns-c32-17 ng-star-inserted sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
								<h2 className="ng-tns-c32-17">Biến động số dư</h2>
							</IonLabel>
						</div>
						<IonRippleEffect className="ng-tns-c32-17 ios hydrated" role="presentation"></IonRippleEffect>
					</IonItem>
					<IonItem
						className="ion-item pl-2 ion-activatable ng-tns-c32-17 ng-star-inserted item-label item ios in-list ion-focusable hydrated"
						onClick={() => history.push("/bet-report")}
					>
						<div _nghost-mri-c5="" className="mr-3 ng-tns-c32-17">
							<IonImg
								className="ion-icon icon-start ng-tns-c32-17 ng-star-inserted ios hydrated"
								slot="start"
								role="img"
								src={`${IMG_MB}assets/account/lichsucuocxoso.png`}
							></IonImg>
						</div>
						<IonIcon slot="end" className="ng-tns-c32-17 ios hydrated" role="img" icon={chevronForward}></IonIcon>
						<div _nghost-mri-c5="" className="ng-tns-c32-17">
							<IonLabel className="ng-tns-c32-17 ng-star-inserted sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
								<h2 className="ng-tns-c32-17">Thống kê đặt cược</h2>
							</IonLabel>
						</div>
						<IonRippleEffect className="ng-tns-c32-17 ios hydrated" role="presentation"></IonRippleEffect>
					</IonItem>
					<IonItem
						className="ion-item pl-2 ion-activatable ng-tns-c32-17 ng-star-inserted item-label item ios in-list ion-focusable hydrated"
						onClick={() => history.push("/bet-history")}
					>
						<div _nghost-mri-c5="" className="mr-3 ng-tns-c32-17">
							<IonImg
								className="ion-icon icon-start ng-tns-c32-17 ng-star-inserted ios hydrated"
								slot="start"
								role="img"
								src={`${IMG_MB}assets/account/lichsucuocxoso.png`}
							></IonImg>
						</div>
						<IonIcon slot="end" className="ng-tns-c32-17 ios hydrated" role="img" icon={chevronForward}></IonIcon>
						<div _nghost-mri-c5="" className="ng-tns-c32-17">
							<IonLabel className="ng-tns-c32-17 ng-star-inserted sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
								<h2 className="ng-tns-c32-17">Lịch sử cược</h2>
							</IonLabel>
						</div>
						<IonRippleEffect className="ng-tns-c32-17 ios hydrated" role="presentation"></IonRippleEffect>
					</IonItem>
					<IonItem
						className="ion-item pl-2 ion-activatable ng-tns-c32-17 ng-star-inserted item-label item ios in-list ion-focusable hydrated"
						onClick={() => history.push("/agency")}
					>
						<div _nghost-mri-c5="" className="mr-3 ng-tns-c32-17">
							<IonImg
								className="ion-icon icon-start ng-tns-c32-17 ng-star-inserted ios hydrated"
								slot="start"
								role="img"
								src={`${IMG_MB}assets/account/daily.png`}
							></IonImg>
						</div>
						<IonIcon slot="end" className="ng-tns-c32-17 ios hydrated" role="img" icon={chevronForward}></IonIcon>
						<div _nghost-mri-c5="" className="ng-tns-c32-17">
							<IonLabel className="ng-tns-c32-17 ng-star-inserted sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
								<h2 className="ng-tns-c32-17">Đại lý</h2>
							</IonLabel>
						</div>
						<IonRippleEffect className="ng-tns-c32-17 ios hydrated" role="presentation"></IonRippleEffect>
					</IonItem>
					<IonItem
						className="ion-item pl-2 ion-activatable ng-tns-c32-17 ng-star-inserted item-label item ios in-list ion-focusable hydrated"
						onClick={() => props.logout()}
					>
						<div _nghost-mri-c5="" className="mr-3 ng-tns-c32-17">
							<IonImg
								className="ion-icon icon-start ng-tns-c32-17 ng-star-inserted ios hydrated"
								slot="start"
								role="img"
								src={`${IMG_MB}assets/account/thoat.png`}
							></IonImg>
						</div>
						<IonIcon slot="end" className="ng-tns-c32-17 ios hydrated" role="img" icon={chevronForward}></IonIcon>
						<div _nghost-mri-c5="" className="ng-tns-c32-17">
							<IonLabel className="ng-tns-c32-17 ng-star-inserted sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
								<h2 className="ng-tns-c32-17">Đăng Xuất</h2>
							</IonLabel>
						</div>
						<IonRippleEffect className="ng-tns-c32-17 ios hydrated" role="presentation"></IonRippleEffect>
					</IonItem>
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default connect(null, auth.actions)(Account);
