import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IonHeader, IonToolbar, IonImg, IonButton, IonLabel } from "@ionic/react";
import { formatMoney } from "../util";
import { useSelector, shallowEqual } from "react-redux";
import LoginModal from "../components/Modal/LoginModal";
import { includes } from "lodash";

import "../pages/Home.css";
import { IMG_MB } from "../constants";

const HeaderHome: React.FC = () => {
	const history = useHistory();
	const query = useMemo(() => new URLSearchParams(window.location.search), []);
	const isLogin = query.get("login");
	const [isShowModalLogin, setIsShowModalLogin] = useState(isLogin === "true");
	const { authToken, userInfo } = useSelector(
		(state: any) => ({
			authToken: state.auth.authToken,
			userInfo: state.auth.user,
		}),
		shallowEqual
	);

	const [isDownload, setIsDownload] = useState(true);

	useEffect(() => {
		const isIos = () => {
			const userAgent = window.navigator.userAgent.toLowerCase();
			return /iphone|ipad|ipod/.test(userAgent);
		};
		const isApp = window.location.hostname === "vibet.app";

		if (getItemWithExpiry("SAVE_DOWNLOAD_MOBILE_APP") === "false" || !isIos || isApp) {
			setIsDownload(false);
		}
	}, []);

	const setItemWithExpiry = (key: string, value: string, ttl: number) => {
		const now = new Date();

		// `item` is an object which contains the original value
		// as well as the time when it's supposed to expire
		const item = {
			value: value,
			expiry: now.getTime() + ttl,
		};
		localStorage.setItem(key, JSON.stringify(item));
	};

	const getItemWithExpiry = (key: string) => {
		const itemStr = localStorage.getItem(key);

		// If the item doesn't exist, return null
		if (!itemStr) {
			return null;
		}

		const item = JSON.parse(itemStr);
		const now = new Date();

		// Compare the expiry time of the item with the current time
		if (now.getTime() > item.expiry) {
			// If the item is expired, delete the item from storage
			// and return null
			localStorage.removeItem(key);
			return null;
		}

		return item.value;
	};

	return (
		<IonHeader className="header-homepage">
			<IonToolbar>
				<IonImg slot="start" className="logo" src={`${IMG_MB}assets/logo-vibet88.png`} onClick={() => history.push("/home")} />
				{authToken ? (
					<div id="menu-header" className="menu-login" slot="end">
						<IonImg className="icon-wallet-login" src={`${IMG_MB}assets/home/wallet.gif`} />
						<IonLabel slot="end" className="text-credits-login">
							{includes(window.location.pathname, "/sport-2/afbsport") ? 0 : formatMoney(userInfo?.Credits)} K
						</IonLabel>
					</div>
				) : (
					<>
						<IonButton className="btn-header" slot="end" onClick={() => setIsShowModalLogin(true)}>
							Đăng nhập
						</IonButton>
						<IonButton className="btn-header " slot="end" onClick={() => history.push("/register")}>
							Đăng ký
						</IonButton>
					</>
				)}
			</IonToolbar>
			{isShowModalLogin && !authToken && <LoginModal setIsShowModalLogin={setIsShowModalLogin} />}
			{isDownload && (
				<div className="mobileApps">
					<div
						className="cancel"
						onClick={() => {
							setItemWithExpiry("SAVE_DOWNLOAD_MOBILE_APP", "false", 12 * 60 * 60 * 1000);
							setIsDownload(false);
						}}
					>
						×
					</div>
					<div className="description">
						<img className="max-w-50px" src={`assets/app-ios.webp`} alt="" />
						<div className="flex flex-col align-items-start">
							<span className="name">VIBET88</span>
							<span className="title">Ứng dụng thể thao, ứng dụng giải trí, chơi mọi lúc, mọi nơi</span>
						</div>
					</div>
					<div className="button">
						<a href="https://apps.apple.com/app/vbat/id6615087164" target="_blank" rel="noreferrer">
							Tải xuống
						</a>
					</div>
				</div>
			)}
		</IonHeader>
	);
};

export default HeaderHome;
